<template>
    <div>
<!--        <common-header hide="true" >-->
<!--            台醫通-->
<!--        </common-header>-->
        <div class="container">

            <!-- Banner -->
            <div class="swiper-container">
                <div class="swiper-wrapper">
<!--                    <div @click="toHref(1)" class="swiper-slide">-->
<!--                        <img class="swiper-slide-img" src="../../assets/images/banner-2.png" alt="">-->
<!--                    </div>-->
<!--                    <div @click="toHref(2)" class="swiper-slide">-->
<!--                        <img class="swiper-slide-img" src="../../assets/images/banner-3.png" alt="">-->
<!--                    </div>-->
                    <div class="swiper-slide">
                      <img class="swiper-slide-img" src="../../assets/images/banner-2.png" alt="">
                    </div>
                    <div class="swiper-slide">
                      <img class="swiper-slide-img" src="../../assets/images/banner-3.png" alt="">
                    </div>
                    <div class="swiper-slide">
                        <img class="swiper-slide-img" src="../../assets/images/banner-1.png" alt="">
                    </div>
                </div>
                <!-- 如果需要分页器 -->
                <div class="swiper-pagination"></div>
            </div>
            <!-- nav -->
<!--            <ul class="m-tab01 clearfix">-->
<!--                <li v-for="icon in iconList" :key="icon.text">-->
<!--                    <a @click="toOfflineApply(icon)" target="_self">-->
<!--                        <img :src="icon.imgSrc" class="tab-icon01">-->
<!--                        <span>{{icon.text}}</span>-->
<!--                    </a>-->
<!--                </li>-->
<!--            </ul>-->
            <!-- 医院资讯 -->
            <div class="title">健康資訊</div>
            <div class="m-wrapper04">
                <ul class="article-list-con02">
                    <li @click="toDiscoverDetail(item)" v-for="item in newsList" :key="item.title">
                        <a>
                            <div class="article-tit01">
                                <span :class="`tag-${item.color}`">{{item.sign}}</span>
                                <h5>{{item.title}}</h5>
                            </div>
                            <div class="article-info01">
                                <span>{{item.time}}</span>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
            <!-- 底部Tab -->
            <common-footer :currentTab="currentTab"></common-footer>
        </div>
    </div>
</template>
<script>
import Swiper from 'swiper'
import {newsList} from '@/constant/constant'
import commonFooter from '../../components/footer'
// import commonHeader from '../../components/header'
import store from '../../store'
import {mapState} from 'vuex'
import {postCode} from '@/utils/tools'
export default({
    store,
    data() {
        return {
            userId: null,
            iconList: [
            //     {
            //     id: 0,
            //     shortName: 'gh',
            //     code: 'yygh',
            //     text: '預約掛號',
            //     imgSrc: require('@/assets/images/icon-xxgh.png'),
            //     href: 'https://wxauth.yihu.com/apiweb/tp.html?point=gh&thirdPartyUserId=' + this.userId + '&appId=9001217&sourceType=2'
            // },{
            //     id: 1,
            //     shortName: 'zx',
            //     text: '在線咨詢',
            //     imgSrc: require('@/assets/images/icon-zxzx.png'),
            //     href: 'http://wdoctoronline.hxcx.com.cn/#/consult/service/describe/NEW2021/22?partner=tprs&productId=4122&userId=023ede1d960868af9d434fce409e4235'
            // },{
            //     id: 2,
            //     shortName: 'zj',
            //     text: '專家預約',
            //     imgSrc: require('@/assets/images/icon-zjyy.png'),
            //     href: '#'
            // },{
            //     id: 3,
            //     code: 'tjfw',
            //     shortName: 'tj',
            //     text: '體檢服務',
            //     imgSrc: require('@/assets/images/icon-tjfw.png'),
            //     href: '#'
            // },{
            //     id: 4,
            //     shortName: 'yp',
            //     text: '藥品導購',
            //     imgSrc: require('@/assets/images/icon-ypdg.png'),
            //     href: '#'
            // },{
            //     id: 5,
            //     shortName: 'bx',
            //     text: '保險導購',
            //     imgSrc: require('@/assets/images/icon-bxfw.png'),
            //     href: '#'
            // },{
            //     id: 6,
            //     shortName: 'jb',
            //     code: 'jbhx',
            //     text: '健保核销',
            //     imgSrc: require('@/assets/images/icon-jbhx.png'),
            //     href: 'https://appweb.dalutone.cn/app/twjb?source=weChat-official-account&to=Twjb&code=08189tll2yIkc74xDmll22cZhr089tlk'
            // },{
            //     id: 7,
            //     shortName: 'jk',
            //     code: 'jkgl',
            //     text: '健康管理',
            //     imgSrc: require('@/assets/images/icon-jkgl.png'),
            //     href: 'http://122.224.144.93:8088/manage-health/'
            // },{
            //     id: 8,
            //     shortName: 'bd', // 健康必讀
            //     code: 'jkzs',
            //     text: '健康知識',
            //     imgSrc: require('@/assets/images/icon-jkzs.png'),
            //     href: 'https://m.yihu.com/index.php/Act/DailyHealth/index.html'
            // }
            ],
            newsList: [],
            bannerList: {
                imgSrc: '',
                href: ''
            },
            currentTab: '/index'
        }
    },
    components: {
        commonFooter
      // ,
      //   commonHeader
    },
    computed: {
        ...mapState({
            storeFuTitle: state => state.changeStoreFuTitle.storeFuTitle
        })
    },
    mounted() {
        this.$store.dispatch('getRealNameInfo').then(res => {
            this.userId = res.data && res.data.id
            // this.iconList[1]['href'].replace('/{userId/}', res.data.id)
        })
        this.newsList = newsList;
        new Swiper('.swiper-container', {
            loop: true,
            spaceBetween: 30,
            // hashNavigation: {
            //     watchState: true,
            // },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            // autoplay: true
        });
    },
    methods: {
        // toHref(num) {
        //     switch(num) {
        //         case 1:
        //             this.$router.push('/mineCard')
        //             break;
        //         case 2:
        //             this.$router.push(`/mineContainer?id=${num}`)
        //             break;
        //     }
        // },
        toOfflineApply(item) {
            let {id, shortName, text} = item;
            if(item.code) {
                postCode(item.code)
            }
            // 线下挂号 --- 618停用
            switch(shortName) {
                case 'gh':
                    window.location.href = 'https://wxauth.yihu.com/apiweb/tp.html?point=gh&thirdPartyUserId=' + this.userId + '&appId=9001217&sourceType=2'
                    break;
                case 'tj':
                    this.$router.push(`/container?from=${shortName}&title=${text}`)
                    break;
                case 'jk':
                    this.$router.push('/jkContainer')
                    break;
                case 'jb':
                case 'bd':
                    this.$router.push(`/container?from=${shortName}&id=${this.userId}`)
                    break;
                case 'yp':
                    this.$router.push(`/common?from=yp&title=藥品導購`)
                    break;
                case 'bx':
                    this.$router.push(`/three?from=bx&title=保險`)
                    // this.$router.push('/bx')
                    break;
                case 'zx':
                    this.$router.push(`/common?from=zx&title=在線咨詢&id=${this.userId}`)
                    break;
                case 'zj':
                    this.$router.push(`/common?from=zj&title=專家預約&id=${this.userId}`)
                    break;
                default:
                    window.location.href = this.iconList[id]['href']
                    // window.open(this.iconList[index]['href'], '_self')
                    break;
            }

        },
        toDiscoverDetail(item) {
            this.$router.push(`/discoverDetail?id=${item.id}&color=${item.color}`)
        }
        // handleTabToggle(tag) {
        //     console.log('tag,', tag)
        //     if(`/tag` === this.currentPage) return;
        //     this.currentPage = `/${tag}`
        //     // 路由跳转
        //     this.$router.push(this.currentPage)
        // }
    }
})
</script>
<style lang="scss" scoped>
.container {
    font-size: 32px;
    min-height: 100vh;
    background: #fff;
    padding-bottom: 50px;
    padding-top: 0px;
    .m-tab01 {
        span {
            font-size: 16px;
            font-family: PingFangSC-regular;
        }

        li {
            width: 33.3333%;
        }
    }

    // 医院资讯
    .article-list-con02 {
        padding: 0 12px;
        box-sizing: border-box;
        li {
            text-align: left;
            &:first-child {
                border-top: 1px solid #eee;
            }
        }
    }
    .article-tit01 {
        white-space: inherit;
        overflow: visible;
        display: flex;
        span {
            flex-shrink: 0;
            min-width: 24px;
        }
        h5 {
            font-size: 14px;
            font-weight: 500;
            display: inline;
            color: #333;
        }
    }
    .title {
        width: 100%;
        height: 38px;
        font-size: 20px;
        color: #101010;
        padding: 20px 20px 40px 12px;
        font-family: PingFangSC-bold;
        text-align: left;
        box-sizing: border-box;
    }
}



.swiper-container{
  height: 132px;
  width: 100%;
  padding: 17px 17px 0 15px;
  box-sizing: border-box;
  .swiper-wrapper{
    .swiper-slide{
      width: 100%;
      height: 100%;
      text-align: center;
      line-height: 120px;
      border-radius: 10px;

      &-img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
      }
    }
  }
}

</style>
